const Navbar = {
  stickNavbar: function(navbar, sticky) {
    if (window.pageYOffset >= sticky) {
      navbar.classList.add("sticky");
      navbar.classList.remove("hidden");
    } else {
      navbar.classList.remove("sticky");
    }
    $(".navbar-sticky--toggle-button").click(function() {
      $(".navbar-sticky--pink-navbar").fadeIn();
      $(".navbar-sticky").fadeIn();
      $(".navbar-sticky--pink-navbar").removeClass("hidden");
      return $(".navbar-sticky").addClass("navbar-sticky-open");
    });
    $(".navbar-sticky--pink-navbar--button-close").click(function() {
      return $(".navbar-sticky--pink-navbar").fadeOut(function() {
        return $(".navbar-sticky").removeClass("navbar-sticky-open");
      });
    });
    return $(".home-scroll, .nav-scroll").click(function() {
      return $(".navbar-sticky--pink-navbar").fadeOut(function() {
        return $(".navbar-sticky").removeClass("navbar-sticky-open");
      });
    });
  },
  bindFadeOnScroll: function() {
    const topHeaderPages = ['/free-app-landing-page'];
    return $(window).scroll(function() {
      if ($(this).scrollTop() > 200 || topHeaderPages.includes(window.location.pathname)) {
        return $(".navbar-container-open").fadeIn("slow");
      } else {
        return $(".navbar-container-open").fadeOut();
      }
    });
  },
  bindClickEvents: function() {
    var active;
    $("#toggleSidebarButton").click(function() {
      $("#toggleSidebar").addClass("active");
      return $("#toggleSidebarButton").toggle();
    });
    $("#toggleSidebarClose").click(function() {
      $("#toggleSidebar").removeClass("active");
      return $("#toggleSidebarButton").toggle();
    });
    $(".navbar-website--list").on("click", "li", function() {
      $(".navbar-website--list li.active--hover").removeClass("active--hover");
      return $(this).addClass("active--hover");
    });
    $(".navbar-website--list").on("mouseover", "li", function() {
      return $(".navbar-website--list li.active--hover:hover").css(
        "color",
        "black"
      );
    });
    $(".navbar-website--list").on("mouseleave", "li", function() {
      return $(".navbar-website--list li.active--hover:hover").css(
        "color",
        "white"
      );
    });
    active = $(".active--hover");
    return $("ul.navbar-website--list li").hover(
      function() {
        $("ul.navbar-website--list li.active--hover").removeClass(
          "active--hover"
        );
        return $(this).addClass("active--hover");
      },
      function() {
        $("ul.navbar-website--list li.active--hover").removeClass(
          "active--hover"
        );
        return active.addClass("active--hover");
      }
    );
  },
  bindStickyNavbar: function() {
    var _this, navbar, isOnScrollActionBound;
    navbar = document.getElementById("navbar-sticky");
    _this = this;
    isOnScrollActionBound = false;
    if (navbar != null) {
      window.onscroll = function() {
        if (isOnScrollActionBound === false) {
          _this.stickNavbar(navbar, navbar.offsetTop);
          isOnScrollActionBound = true;
        }

      };
    }
  },
  bindSmoothScroll: function() {
    [...document.querySelectorAll('.nav-scroll, .link-item, .home-scroll')].forEach((div) => {
      div.addEventListener("click", (event) => {
        scrollToSelector = event.target.dataset["target"];
        if (scrollToSelector) {
          elementToScroll = document.querySelector(scrollToSelector);
          if (elementToScroll) {
            event.preventDefault();
            elementToScroll.scrollIntoView({ behavior: 'smooth' });
          }
        }
      });

    })
  },
};

$(document).on("turbolinks:load", function() {
  displayNavbar = document.querySelector(".display-navbar");
  if (displayNavbar == null) Navbar.bindFadeOnScroll();
  Navbar.bindClickEvents();
  Navbar.bindStickyNavbar();
  Navbar.bindSmoothScroll();
});
